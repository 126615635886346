<template>
  <div class="about">
    <h1>This is an about page</h1>
    <p>It requires authentication based on the lack of an allowAnonymous meta flag in the route definition.</p>
    <b-button
      variant="danger"
      @click="logout"
    >Log Out</b-button>
    <hr>
    <PlaidLink :company-id="1"></PlaidLink>
    <hr>
    <PlaidLink
      :company-id="1"
      :item-id="2"
      button-title="Renew Plaid Auth"
      variant="warning"
    ></PlaidLink>
    <pre class="text-left">{{ user }}</pre>
  </div>
</template>
<script>
import StorageService from '@/services/StorageService'
import UserService from '@/services/UserService'

import PlaidLink from '@/components/plaid/Link'

export default {
  name: 'About',
  components: { PlaidLink },
  data () {
    return {
      user: null
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.user = StorageService.get('user')
    },

    logout () {
      UserService.logout()
    }
  }
}
</script>
